import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fire from '../../fire';
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  setDoc,
  deleteDoc,
  doc,
  orderBy,
  // setDoc,
  // onSnapshot,
  query,
  where,
  // orderBy,
  limit,
  getDoc,
  // getDoc,
  // startAt,
  // endAt,
} from "firebase/firestore";
import { commonConstants } from "../../util/constants/common.constants";
import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from "@firebase/storage";
import { formatDateDDMMYYYHHMM, formatDateDDMMYYYHHMM_min } from "../../util/common.util";
import { initialContent, initialForm } from "../../util/forms.util";
import { handleError, lttHandleError, lttInitOffline } from "../loretta.utilities";

export const login = createAsyncThunk(`${commonConstants.LORETTA}/login`, async (payload) => {
  const { email, password } = payload;
  return fire.auth().signInWithEmailAndPassword(email, password)
    .then(async (userCredential) => {
      // Signed in
      const user = userCredential.user;
      localStorage.setItem('user', JSON.stringify(user));

      const db = getFirestore(fire.auth().app);

      const payloadSesion = {
        ...payload,
        createdAt: new Date(),
      }
      const snap = await addDoc(collection(db, commonConstants.SESSION), payloadSesion);
      return {
        // payloadSesion,
        id: snap.id
      };
    })

    .catch((error) => {
      //console.log(error)
      throw error;
    });

});

export const logout = createAsyncThunk(`${commonConstants.LORETTA}/logout`, async () => {

  localStorage.removeItem('user');
  return fire.auth().signOut()
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
});

export const isLoggedIn = createAsyncThunk(`${commonConstants.LORETTA}/isLoggedIn`, async () => {
  const checkLogin = new Promise((resolve) => {
    const user = fire.auth().currentUser;
    resolve(user);
  });

  return checkLogin.then((user) => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
    return user != null;
  }).catch((error) => {
    throw error;
  });
});


export const fetchData = createAsyncThunk(`${commonConstants.LORETTA}/fetchData`, async (payload) => {

  const { coll, id, toList } = payload;

  const db = getFirestore(fire.auth().app);
  const customerQuery = query(
      collection(db, coll),
      orderBy('createdAt', 'desc')
      // where('uid', '==', id),    
    );
    return fetchData_({ coll: coll, id: id, toList: toList, customerQuery: customerQuery });

});

const toDate = (data, key) => {
  if (!data) return;
  try {
    let date = data[key] ? formatDateDDMMYYYHHMM(new Date(data[key].seconds * 1000 + data[key].nanoseconds / 1000000)) : null;
    return date;
  } catch (error) {
    //console.log(data, error)
  }
}
const fetchData_ = async (payload) => {
  const { coll, id, toList, customerQuery } = payload;

  if (id) {
    const db = getFirestore(fire.auth().app);
    return getDoc(doc(db, coll, id)).then((snap => {
      if(snap.exists() ){
        let data = snap.data();
          // let createdAt = data.createdAt ? formatDateDDMMYYYHHMM(new Date(data.createdAt.seconds * 1000 + data.createdAt.nanoseconds / 1000000)) : null;
          let createdAt = toDate(data, 'createdAt');
          let startAt = toDate(data, 'startAt');
          let endAt = toDate(data, 'endAt');
          return {data: {
            ...data,
            id: snap.id,
            key: snap.id,
            createdAt,
            startAt,
            endAt,
            selected: false,
          }, toList: toList
          }
      }
      


      return null;
    })).catch(e => {
      let error = lttHandleError(e);
      throw { error };
    })
  } else {
    // const customerQuery = query(
    //   collection(db, coll),
    //   orderBy('createdAt', 'desc')
    // );

    try {
      const querySnapshot = await getDocs(customerQuery);
      const newData = querySnapshot.docs
        .map((snap_1) => {
          let data = snap_1.data();
          // let createdAt = data.createdAt ? formatDateDDMMYYYHHMM(new Date(data.createdAt.seconds * 1000 + data.createdAt.nanoseconds / 1000000)) : null;
          let createdAt = toDate(data, 'createdAt');
          let startAt = toDate(data, 'startAt');
          let endAt = toDate(data, 'endAt');

          let ret = {
            ...data,
            // createdAt: data.createdAt ? formatDateDDMMYYYHHMM(new Date(data.createdAt.seconds * 1000 + data.createdAt.nanoseconds / 1000000)) : null,
            id: snap_1.id,
            key: snap_1.id,
            createdAt,
            startAt,
            endAt,
            selected: false,
          };
          return ret;
        });
      // return newData;
      const data = newData.length > 0 ? (toList ? newData : newData[0]) : [];
      return { data, toList };
    } catch (e_1) {
      throw e_1;
    }
    // const data = 
  }

};

export const fetchCompany = createAsyncThunk(`${commonConstants.LORETTA}/fetchCompany`, async () => {
  return fetchData_({ coll: "company", id: "congroup", toList: false, customerQuery: null });
});

// export const fetchAll = createAsyncThunk(`${commonConstants.LORETTA}/fetchAll`, async () => {
//   const db = getFirestore(fire.auth().app);
//   const customerQuery = query(
//     collection(db, commonConstants.COLL_CONTENT),
//     orderBy('createdAt', "desc"),
//     limit(commonConstants.QUERY_LIMIT_50)
//   );

//   const objs = await getDocs(customerQuery)
//     .then((querySnapshot) => {
//       const newData = querySnapshot.docs
//         .map((snap) => {
//           let data = snap.data();
//           let ret =
//           {
//             ...data,
//             createdAt: data.createdAt ? formatDateDDMMYYYHHMM(new Date(data.createdAt.seconds * 1000 + data.createdAt.nanoseconds / 1000000)) : null,
//             // updatedAt: data.updatedAt ? formatDateDDMMYYYHHMM(new Date(data.updatedAt.seconds * 1000 + data.updatedAt.nanoseconds / 1000000)) : null,
//             id: snap.id,
//           };
//           return ret;
//         });
//       return newData;
//     })
//   return objs;
// });

export const fetchNews = createAsyncThunk(`${commonConstants.LORETTA}/fetchNews`, async () => {
  const db = getFirestore(fire.auth().app);
  const customerQuery = query(
    collection(db, "content"),
    where('contentType', '==', 'newsletter'),
    where('active', '==', true),
    orderBy('createdAt', "desc"),
    limit(commonConstants.QUERY_LIMIT_50)
  );

  return fetchData_({ customerQuery, toList: true });
});

export const fetchMedia = createAsyncThunk(`${commonConstants.LORETTA}/fetchMedia`, async () => {
  const db = getFirestore(fire.auth().app);
  const customerQuery = query(
    collection(db, commonConstants.COLL_CONTENT),
    where('contentType', '==', 'media'),
    where('active', '==', true),
    orderBy('createdAt', "desc"),
    limit(commonConstants.QUERY_LIMIT_50)
  );
  return fetchData_({ customerQuery, toList: true });
});

export const fetchBanner = createAsyncThunk(`${commonConstants.LORETTA}/fetchBanner`, async () => {
  const db = getFirestore(fire.auth().app);
  const customerQuery = query(
    collection(db, commonConstants.COLL_CONTENT),
    where('contentType', '==', 'banner'),
    where('active', '==', true),
    orderBy('createdAt', "desc"),
    limit(commonConstants.QUERY_LIMIT_50)
  );
  return fetchData_({ customerQuery, toList: true });

});

export const fetchArticles = createAsyncThunk(`${commonConstants.LORETTA}/fetchArticles`, async () => {
  const db = getFirestore(fire.auth().app);
  const customerQuery = query(
    collection(db, commonConstants.COLL_CONTENT),
    where('active', '==', true),
    where('contentType', '==', "article"),
    orderBy('createdAt', "desc"),
    limit(commonConstants.QUERY_LIMIT_50)
  );

  return fetchData_({ customerQuery, toList: true });

  // const objs = await getDocs(customerQuery)
  //   .then((querySnapshot) => {
  //     const newData = querySnapshot.docs
  //       .map((snap) => {
  //         let data = snap.data();
  //         // let ret = {
  //         //   ...data,
  //         //   id: snap.id,
  //         //   // src: data.imageSrc,
  //         //   // altText: data.tittle,
  //         //   // caption: data.subtittle,
  //         //   // key: snap.id,
  //         // }
  //         let ret =
  //         {
  //           ...data,
  //           createdAt: data.createdAt ? formatDateDDMMYYYHHMM(new Date(data.createdAt.seconds * 1000 + data.createdAt.nanoseconds / 1000000)) : null,
  //           startAt: data.startAt ? formatDateDDMMYYYHHMM(new Date(data.startAt.seconds * 1000 + data.startAt.nanoseconds / 1000000)) : null,
  //           endAt: data.endAt ? formatDateDDMMYYYHHMM(new Date(data.endAt.seconds * 1000 + data.endAt.nanoseconds / 1000000)) : null,
  //           // updatedAt: data.updatedAt ? formatDateDDMMYYYHHMM(new Date(data.updatedAt.seconds * 1000 + data.updatedAt.nanoseconds / 1000000)) : null,
  //           id: snap.id,
  //         };
  //         return ret;
  //       });
  //     return newData;
  //   })
  // return objs;
});

export const fetchServices = createAsyncThunk(`${commonConstants.LORETTA}/fetchServices`, async () => {
  const db = getFirestore(fire.auth().app);
  const customerQuery = query(
    collection(db, commonConstants.COLL_CONTENT),
    where('active', '==', true),
    where('contentType', '==', "service"),
    orderBy('createdAt', "desc"),
    limit(commonConstants.QUERY_LIMIT_50)
  );

  return fetchData_({ customerQuery, toList: true });

  // const objs = await getDocs(customerQuery)
  //   .then((querySnapshot) => {
  //     const newData = querySnapshot.docs
  //       .map((snap) => {
  //         let data = snap.data();
  //         // let ret = {
  //         //   ...data,
  //         //   id: snap.id,
  //         //   // src: data.imageSrc,
  //         //   // altText: data.tittle,
  //         //   // caption: data.subtittle,
  //         //   // key: snap.id,
  //         // }
  //         let ret =
  //         {
  //           ...data,
  //           createdAt: data.createdAt ? formatDateDDMMYYYHHMM(new Date(data.createdAt?.seconds * 1000 + data.createdAt.nanoseconds / 1000000)) : null,
  //           startAt: data.startAt ? formatDateDDMMYYYHHMM(new Date(data.startAt?.seconds * 1000 + data.startAt.nanoseconds / 1000000)) : null,
  //           // endAt: data.endAt ? formatDateDDMMYYYHHMM(new Date(data.endAt?.seconds * 1000 + data.endAt.nanoseconds / 1000000)) : null,
  //           endAt: null,

  //           // updatedAt: data.updatedAt ? formatDateDDMMYYYHHMM(new Date(data.updatedAt.seconds * 1000 + data.updatedAt.nanoseconds / 1000000)) : null,
  //           id: snap.id,
  //         };
  //         return ret;
  //       });
  //     return newData;
  //   })
  // return objs;
});

export const fetchEmails = createAsyncThunk(`${commonConstants.LORETTA}/fetchEmails`, async (payload) => {

  const { coll, id, toList } = payload;

  const db = getFirestore(fire.auth().app);
  const customerQuery = query(
      collection(db, coll),
      orderBy('email', 'asc')
      // where('uid', '==', id),    
    );
    return fetchData_({ coll: coll, id: id, toList: toList, customerQuery: customerQuery });

});


export const objAdded = createAsyncThunk(`${commonConstants.LORETTA}/objAdded`, async (payload) => {

  const { coll, obj } = payload;
  const db = getFirestore(fire.auth().app);

  if (obj.id) {
    const docRef = doc(db, coll, obj.id);
    const objs = await setDoc(docRef, obj, { merge: true })
      .then(() => {
        return {
          ...obj,
        };
      }).catch((e) => {
        throw e;
      });
    return objs;

  } else {

  }
  const objs = await addDoc(collection(db, coll), obj, { merge: true })
    .then((snap) => {
      return {
        id: snap.id
      };
    }).catch((e) => {
      throw e;
    });
  return objs;
});

export const objDeleted = createAsyncThunk(`${commonConstants.LORETTA}/objDeleted`, async ({ coll, id }) => {

  const db = getFirestore(fire.auth().app);
  const docRef = doc(db, coll, id);

  if (docRef)
    return deleteDoc(docRef).
      then(() => {
        //console.log("successfully deleted! ")
        return {
          id
        }
      })
      .catch((error) => {
        //console.log("Error removing document:", error)
        throw (error);
      })
  return "";
});

export const custAdded = createAsyncThunk(`${commonConstants.LORETTA}/custAdded`, async (payload) => {
  const db = getFirestore(fire.auth().app);
  const objs = await addDoc(collection(db, commonConstants.COLL_CUSTOMERS), payload, { merge: true })
    .then((snap) => {
      return {
        ...payload,
        // createdAt: formatDateDDMMYYYHHMM(payload.createdAt),
        id: snap.id
      };
    })
  return objs;
});

export const objUpdated = createAsyncThunk(`${commonConstants.LORETTA}/objUpdated`, async (payload) => {
  const { coll, obj } = payload;
  const db = getFirestore(fire.auth().app);
  // let createdAt_ = obj.createdAt;
  delete obj.createdAt;
  const docRef = doc(db, coll, obj.id);
  const objs = await setDoc(docRef, obj, { merge: true })
    .then((o) => {
      return {
        ...obj,
        // createdAt: createdAt_,
        // updatedAt: formatDateDDMMYYYHHMM(payload.updatedAt),
      };
    }).catch((e) => {
      throw e;
    });
  return objs;
});

export const addFile = createAsyncThunk(`${commonConstants.LORETTA}/addFile`, async (payload) => {

  // const {file} = action.payload;
  // const files= [...state.files, file];
  // state.files = files;

  const { contentType, file } = payload;
  if (!file) return;

  let uuii = formatDateDDMMYYYHHMM_min(new Date())
  const storage = getStorage(fire.auth().app);

  const pathReference = ref(storage, `${contentType}/${uuii}-${file.fileName}`);

  return uploadBytes(pathReference, file.file).then((snapshot) => {

    return getDownloadURL(snapshot.ref) // Lo sube y tengo la URL
      .then((url) => {
        delete file.file;

        const payload_ = {
          ...file,
          fileSrc: url,
        }

        return payload_;

      }).catch((e) => { throw e });
  }).catch((e) => { throw e });
});

export const removeFile = createAsyncThunk(`${commonConstants.LORETTA}/removeFile`, async (payload) => {


  const { fileSrc, i } = payload;

  const storage = getStorage(fire.auth().app);
  const pathReference = ref(storage, fileSrc);

  return deleteObject(pathReference).then(() => {
    // File deleted successfully
    return i;
  }).catch((e) => { throw e });
});


export const storageFile = createAsyncThunk(`${commonConstants.LORETTA}/storageFile`, async (payload) => {
  const { contentType, file } = payload;
  if (!file) return;

  let uuii = formatDateDDMMYYYHHMM_min(new Date())
  const storage = getStorage(fire.auth().app);

  const pathReference = ref(storage, `${contentType}/${uuii}-${file.name}`);

  return uploadBytes(pathReference, file).then((snapshot) => {

    return getDownloadURL(snapshot.ref) // Lo sube y tengo la URL
      .then((url) => {
        //console.log(url);

        const defaultObj = initialContent({ contentType: { contentType } });
        delete payload.file;

        const payload_ = {
          ...defaultObj,
          ...payload,
          fileSrc: url,
        }

        const db = getFirestore(fire.auth().app);
        return addDoc(collection(db, 'content'), payload_, { merge: true })
          .then((snap) => {
            return {
              ...payload_,
              createdAt: formatDateDDMMYYYHHMM(payload.createdAt),
              id: snap.id
            };
          })
      }).catch(e => {
        //console.log(e);
        throw (e);
      })

  });

});

export const storageImage = createAsyncThunk(`${commonConstants.LORETTA}/storageImage`, async (payload) => {
  const { contentType, image } = payload;
  if (!image) return;

  let uuii = formatDateDDMMYYYHHMM_min(new Date())
  const storage = getStorage(fire.auth().app);

  const pathReference = ref(storage, `${contentType}/${uuii}-${image.name}`);

  return uploadBytes(pathReference, image).then((snapshot) => {

    return getDownloadURL(snapshot.ref) // Lo sube y tengo la URL
      .then((url) => {
        //console.log(url);
        delete payload.image;

        const payload_ = {
          ...payload,
          imageSrc: url,
        }

        const db = getFirestore(fire.auth().app);
        return addDoc(collection(db, 'content'), payload_, { merge: true })
          .then((snap) => {
            return {
              id: snap.id
            };
          })
      }).catch(e => {
        //console.log(e);
        throw (e);
      })

  });

});


export const sendMail = createAsyncThunk(`${commonConstants.COLL_LANDING}/sendMail`, async (doc) => {
  const payload = {
    ...doc,
    user: '', //user.email,
  }
  const db = getFirestore(fire.auth().app);

  const objs = await addDoc(collection(db, "mailTo"), payload, { merge: true })
    .then((snap) => {
      return {
        ...payload,
        id: snap.id
      };
    }).catch((e) => { throw e })
  return objs;
})

let user = JSON.parse(localStorage.getItem('user'));
const initialState = {
  user: user || null,
  form: null,
  files: [],

  company: null,
  banners: null,
  articles: null,
  media: null,
  services: null,
  news: null,

  entities: null,
  entitiesFilter: null,
  toMail: null,
  loading: false,
  online: false,
  info: null,
  error: null,
  infoLoretta: null,

  //For remove
  obj: null,
  mediaAndNews: null,
  addNew: null,
  nav: '',
  reload: false,
  logged: user ? !user.isAnonymous : false,
  // content: <Home/>,
};

const lorettaSlice = createSlice({
  name: commonConstants.LORETTA,
  initialState: initialState,
  reducers: {
    init(state, action) {
      state = { ...initialState };
    },
    initObj(state, action) {
      state.obj = { ...initialState.obj };
    },
    initForm(state, action) {
      const { idForm } = action.payload;
      const form = initialForm(idForm);
      state.obj = null;
      // state = {...initialState,
      //   form,
      // }
      state.form = form;
    },
    initOffline(state, action) {
      const { banners, articles, mediaAndNews, media, news, services } = lttInitOffline()
      state.banners = banners;
      state.articles = articles;
      state.mediaAndNews = mediaAndNews;
      state.media = media;
      state.news = news;
      state.services = services;
    },
    stsChange(state, action) {
      const { name, value } = action.payload;
      state[name] = value;
    },
    checkMail(state, action){
      const {id, selected} = action.payload;
      let obj = state.toMail.find((obj) => obj.id === id);
      obj.selected = selected
    },
    objSelected(state, action) {
      const id = action.payload;
      state.obj = state.entities.find((obj) => obj.id === id);
    },
    objChange(state, action) {
      const { name, value } = action.payload;
      const { obj } = state;
      if (obj) {
        obj[name] = value;
        const index = state.entities.findIndex((obj_) =>
          obj_.id === state.obj.id
        )
        if (index) {
          state.entities[index] = obj;
        }
      }
    },
    filterFn(state, action) {
      if(action.payload = ''){
        state.entitiesFilter = state.entities;
      }
      state.entitiesFilter = state.entities.filter((obj) => obj['idDoc'].includes(action.payload));

      // const existingObj = state.entities.find((obj) => obj.id === id);
      //   if (existingObj) {
      //     state.entities = state.entities.filter((obj) => obj.id !== id);
      //   }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        let user_ = JSON.parse(localStorage.getItem('user'));
        state.loading = false;
        state.user = user_;
        state.logged = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.user = null
        state.error = action.error?.message;
        state.logged = false;
      })

      .addCase(isLoggedIn.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(isLoggedIn.fulfilled, (state, action) => {
        // let user_ = !action.payload ? { ...action.payload } : null;
        state.loading = false;
        // state.user = user_;
        state.logged = action.payload;
      })
      .addCase(isLoggedIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      })

      .addCase(logout.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false;
        state.user = null; //{ ...initialState.user };
        state.logged = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.user = { ...initialState.user }
        state.error = action.error?.message;
        state.logged = false;
      })
      .addCase(fetchData.pending, (state, action) => {
        state.loading = true;
        state.entities = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        const { data, toList } = action.payload;
        state.loading = false;

        if (toList) {
          state.entities = [...data];
        } else {
          state.obj = { ...data };
        }
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      })
      .addCase(fetchCompany.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.loading = false;
        state.company = data;
        //console.log("fetchCompany", action.payload)
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      })
      .addCase(fetchBanner.pending, (state, action) => {
        state.loading = true;
        state.entities = null;
      })
      .addCase(fetchBanner.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.banners = data;
        state.entities = data;
        //console.log("fetchBanner", action.payload)
      })
      .addCase(fetchBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
        //console.log(action.error.message)
      })
      .addCase(fetchServices.pending, (state, action) => {
        state.loading = true;
        state.entities = null;
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.services = data;
        state.entities = data;
        //console.log("fetchServices", action.payload)
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
        //console.log(action.error.message)
      })
      .addCase(fetchNews.pending, (state, action) => {
        state.loading = true;
        state.entities = null;
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        const { data, toList } = action.payload;
        state.loading = false;

        if (toList) {
          state.news = [...data];
          state.entities = [...data];
        } else {
          state.obj = { ...data };
        }
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
        //console.log(action.error.message)
      })
      .addCase(fetchMedia.pending, (state, action) => {
        state.loading = true;
        state.entities = null;
      })
      .addCase(fetchMedia.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.media = data;
        state.entities = data;
        //console.log("fetchMedia", action.payload)
      })
      .addCase(fetchMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
        //console.log(action.error.message)
      })
      .addCase(fetchArticles.pending, (state, action) => {
        state.loading = true;
        state.entities = null;
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.articles = data;
        state.entities = data;
        //console.log("fetchArticles", action.payload)
      })
      .addCase(fetchArticles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
        //console.log(action.error.message)
      })
      .addCase(fetchEmails.pending, (state, action) => {
        state.loading = true;
        state.toMail = null;
      })
      .addCase(fetchEmails.fulfilled, (state, action) => {
        const { data } = action.payload;
        
        const data_ = data?.map(({id, email, selected}) => {
          return {id, email, selected}
        });
        state.loading = false;
        state.toMail = data_;
        console.log("fetch emails", data)
      })
      .addCase(fetchEmails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
        state.toMail = null;
        //console.log(action.error.message)
      })
      .addCase(storageFile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(storageFile.fulfilled, (state, action) => {
        state.obj = { ...action.payload };
        // state.entities.push(action.payload);
        state.loading = false;
        // state.entities = [...action.payload];
        state.info = "Publicado"
      })
      .addCase(storageFile.rejected, (state, action) => {
        state.obj = null;
        state.loading = false;
        state.error = action.error?.message;
      })
      .addCase(addFile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addFile.fulfilled, (state, action) => {
        state.loading = false;
        const files = [...state.files, action.payload];
        state.files = files;
        state.info = "Archivo cargado en el servidor de CONGROUPS SAS"
      })
      .addCase(addFile.rejected, (state, action) => {
        state.obj = null;
        state.loading = false;
        state.error = action.error?.message;
      })

      .addCase(removeFile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeFile.fulfilled, (state, action) => {
        state.loading = false;

        const i = action.payload;
        const files = state.files;
        files.splice(i, 1);
        state.files = files;

        state.info = "Archivo eliminado del servidor de CONGROUPS SAS"
      })
      .addCase(removeFile.rejected, (state, action) => {
        state.obj = null;
        state.loading = false;
        state.error = action.error?.message;
      })

      .addCase(storageImage.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(storageImage.fulfilled, (state, action) => {
        state.obj = { ...action.payload };
        // state.entities.push(action.payload);
        state.loading = false;
        // state.entities = [...action.payload];
        state.info = "Publicado"
      })
      .addCase(storageImage.rejected, (state, action) => {
        state.obj = null;
        state.loading = false;
        state.error = action.error?.message;
      })

      .addCase(sendMail.pending, (state, action) => {
        state.loading = true;
        // state.mail= null;
      })
      .addCase(sendMail.fulfilled, (state, action) => {
        state.loading = false;
        state.obj = null;
        // state.mail = `Su mensaje fue enviado con copia a ${action.payload.to[0]}`;
        state.info = "El mensaje fue envíado"
      })
      .addCase(sendMail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      })
      .addCase(objAdded.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(objAdded.fulfilled, (state, action) => {
        state.obj = null;
        state.loading = false;
        state.info = "Registro exitoso"
      })
      .addCase(objAdded.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      })
      .addCase(objUpdated.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(objUpdated.fulfilled, (state, action) => {
        state.obj = action.payload;
        state.loading = false;
        state.info = "Se actualizo el registro"
        // const index = state.entities.findIndex((obj_) =>
        //   obj_.id === action.payload.id
        // )
        // const newEntities = [...state.entities]
        // newEntities[index] = action.payload;
        // state.entities = newEntities
      })
      .addCase(objUpdated.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      })

      .addCase(objDeleted.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(objDeleted.fulfilled, (state, action) => {
        state.obj = null;
        // state.entities.push(action.payload);
        state.loading = false;
        state.info = "Se elimino el registro";
        state.reload = true;
        // state.entities = [...action.payload];

        const { id } = action.payload;
        const existingObj = state.entities.find((obj) => obj.id === id);
        if (existingObj) {
          state.entities = state.entities.filter((obj) => obj.id !== id);
        }

      })
      .addCase(objDeleted.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      })


      .addDefaultCase((state, action) => {
        //console.log(state, action)
      })
  },
});

export const { init, initObj, initForm, initOffline, stsChange, objChange, filterFn, checkMail } = lorettaSlice.actions;

export default lorettaSlice.reducer;
