import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { custAdded, fetchArticles, fetchBanner, fetchData, fetchMedia, fetchNews, fetchServices, init, initForm, objDeleted, stsChange, filterFn } from "../../slices/lorettaSlice";
import { commonConstants } from "../../../util/constants/common.constants";
import { Alert, Button, Table } from "reactstrap";
import { formConstants } from "../../../util/constants/form.constans";
import { initialForm } from "../../../util/forms.util";

const List = (props) => {

    const [filter, setFilter] = useState('');

    const defineParms = (props) => {
        const { id, coll, toList, idForm } = props;

        const locSeacrh = new URLSearchParams(location.search);

        const coll_ = locSeacrh.get('coll');
        const id_ = locSeacrh.get('id');
        const list = locSeacrh.get('list');
        const toList_ = list === "true";
        const idForm_ = locSeacrh.get('idForm');

        var coll__ = coll ? coll : coll_;
        var id__ = id ? id : id_;
        var toList__ = coll ? toList : toList_;
        var idForm__ = coll ? idForm : idForm_;

        return { id: id__, coll: coll__, toList: toList__, idForm: idForm__ }
    }

    const location = useLocation();
    const { id, coll, idForm } = defineParms(props, location);
    const { entitiesFilter, entities, reload } = useSelector((state) => state.loretta);
    const dispatch = useDispatch();

    const form = initialForm(idForm);
    const [form_, setForm_] = useState(form);

    useEffect(() => {
        dispatch(initForm({ idForm }));
        
        if (!entities) {
            dispatch(fetch());
        }
        // fetch();
    }, [])


    const records_ = entitiesFilter ? entitiesFilter : entities;
    const [records, setRecords] = useState(records_)
    // if(records_) setRecords(records_);


    const fetch = () => {
        switch (idForm) {
            case formConstants.SERVICE:
                return fetchServices();
            case formConstants.NEWS:
                return fetchNews();
            case formConstants.ARTICLE:
                return fetchArticles();
            case formConstants.MEDIA:
                return fetchMedia();
            case formConstants.BANNER:
                return fetchBanner();
            default:
                return fetchData({ coll, toList: true });
        }
    }

    const buttonBar = (add, urlAdd) => {
        return <div className="row action-bar">
            <div className="col-12">
                <Button
                    onClick={() => dispatch(fetch())}
                    className="button-primary"
                >
                    <span className="btn-inner--icon">
                        <i className="fa fa-rotate-right" />&nbsp;
                    </span>
                    {commonConstants.LBL_UPALL}
                </Button>
                <Button
                    onClick={() => window.location = urlAdd}
                    className="button-primary"
                >
                    <span className="btn-inner--icon">
                        <i className="fa fa-plus" />&nbsp;
                    </span>
                    {commonConstants.LBL_NEW}
                </Button>
            </div>
        </div>
    }

    const headerBar = () => {
        if(!form_.label) return;
        let urlAdd = "";

        switch (idForm) {
            case formConstants.NEWS:
                urlAdd = `../${commonConstants.URL_NEWS_ADD}/?coll=${coll}&idForm=${idForm}`;
                break;

            default:
                urlAdd = `../${commonConstants.URL_DATA_ADD}/?coll=${coll}&idForm=${idForm}`;
                break;
        }

        let bar = buttonBar(null, urlAdd);
        return bar;
    }

    const headerTable = () => {
        if(!form_.label) return;

        let header = Object.entries(form_.fields)
            .map(([key, value]) => value.labelHD ? value.labelHD : (value.labelES ? value.labelES : `${key}`)
            );

        return <tr>
            {
                header.map((item) => {
                    return <th key={item}>
                        {item}
                    </th>
                })
            }
        </tr>
    }

    const shotBt = (id) => {
        if(!form_.label) return;

        return <td>
                            <React.Fragment>
                                <Link to={`../${commonConstants.URL_DATA_SHOW}/?coll=${coll}&id=${id}&idForm=${idForm}`}>
                                    <span className="btn-inner--icon">
                                        <i className="fa fa-search" />
                                    </span>
                                </Link>
                            </React.Fragment>
                        </td>

    }

    const editBt = () => {
        
    }

    const deleteBt = (id) => {
        if(!form_.label) return;
        return <td>
                            <React.Fragment>
                                <Link onClick={(e) => remove(e, commonConstants.COLL_CONTENT, id)}>
                                    <span className="btn-inner--icon">
                                        <i className="fa fa-trash" />
                                    </span>
                                </Link>
                            </React.Fragment>
                        </td>
    }

    const bodyTable = () => {
        let body = <></>;

        body = <>
            {entities ?
                entities.map((obj, i) => {

                    let keys = Object.entries(form_.fields)
                        .map(([key, value]) => {

                            if (key == 'image' || key == 'file' || key == 'video') {
                                return <td key={key}>
                                    {obj[`${key}Src`] &&
                                        <Link to={obj[`${key}Src`]} target="_blank">
                                            <span>
                                                <i className="fa fa-download"></i>
                                            </span>
                                        </Link>
                                    }
                                </td>
                            }
                            if (key == 'files') {
                                return <td key={key}>
                                    {obj.files?.length}
                                </td>
                            }

                            return <td key={key}>{obj[key]}</td>
                        }
                        );

                    return <tr key={obj.id} >
                        {keys}
                        {shotBt(obj.id)}
                        {deleteBt(obj.id)}
                    </tr>
                }) :
                <>
                    <tr><td>
                        {commonConstants.MSG_EMPTY_DATA_}
                    </td></tr>
                </>
            }
        </>

        return body;
    }

    const tittle = () => {
        if(!form_.label) return;
        return <h2>{`Listado de [${form_?.labels}]`}</h2>
    }

    const remove = (e, coll, id) => {
        e.preventDefault();
        dispatch(objDeleted({ coll, id }))
    }

    const searchFunc = (e) =>{
        e.preventDefault();
        dispatch(filterFn(filter));
        // var input, filter, table, tr, td, i, txtValue;
        // input = document.getElementById("myInput");
        // filter = input.value.toUpperCase();
        // table = document.getElementById("myTable");
        // tr = table.getElementsByTagName("tr");
        // for (i = 0; i < tr.length; i++) {
        //     td = tr[i].getElementsByTagName("td")[0];
        //     if (td) {
        //     txtValue = td.textContent || td.innerText;
        //     if (txtValue.toUpperCase().indexOf(filter) > -1) {
        //         tr[i].style.display = "";
        //     } else {
        //         tr[i].style.display = "none";
        //     }
        //     }       
        // }

    }

    const filterInput = () => {
        return <input type="text" id="myInput" onKeyUp={(e) => searchFunc(e)} placeholder="Search.." value={filter} onChange={(e) => setFilter(e.target.value)} ></input>
    }

    return <Container>
        {
            form_ && <>
                {tittle()}
                {/* {filterInput()} */}
                {headerBar()}
                <Table striped bordered hover>
                    <thead>
                        {headerTable()}
                    </thead>
                    <tbody>
                        <React.Fragment>
                            {bodyTable()}
                        </React.Fragment>
                    </tbody>

                </Table>
            </>
        }
    </Container>
}

export default List;